import { BaseEntity, MemoizeGetters } from './base';
import { Vehicle, VehicleParams, VehicleSource } from './vehicle';

@MemoizeGetters
export class UberUserData extends BaseEntity {
  userHasAccount?: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  uberVehicleUuid?: string;
  vehicleMake?: string;
  vehicleModel?: string;
  vehicleModelUid?: string;
  vehicleYear?: number;
  vehicleColor?: string;
  vehicleColorName?: string;

  get validVehicleInfo() {
    return Boolean(this.uberVehicleUuid && this.vehicleModelUid && this.vehicleYear);
  }

  get vehicleColorNameNullable() {
    return this.vehicleColorName ? `, ${this.vehicleColorName}` : '';
  }

  get vehicleDescription(): string | null {
    return this.validVehicleInfo
      ? `${this.vehicleYear} ${this.vehicleMake} ${this.vehicleModel}${this.vehicleColorNameNullable}`
      : null;
  }

  get vehicleCreateParams(): VehicleParams {
    return {
      vehicleModelUid: this.vehicleModelUid,
      color: this.vehicleColorName,
      year: this.vehicleYear,
      vehicleSource: VehicleSource.Uber,
      sourceIdentifier: this.uberVehicleUuid,
    };
  }

  matchesVehicle(vehicle: Vehicle): boolean {
    const matchesId = vehicle.sourceIdentifier === this.uberVehicleUuid;
    const matchesSource = vehicle.vehicleSource === VehicleSource.Uber;
    return matchesId && matchesSource;
  }
}
