import { AddressParams, addressParamsForGooglePlaceData } from 'src/app/models/user-address';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInput, IonSearchbar, Platform } from '@ionic/angular';
import { NavigationService, QueryParamKey } from 'src/app/navigation.service';
import { Observable, bindCallback, of } from 'rxjs';
import { SignupSource, UserService } from 'src/app/services/api/user/user.service';
import { finalize, flatMap, map } from 'rxjs/operators';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { BackButtonService } from 'src/app/services/back-button/back-button.service';
import { FlowPage } from '../../flow-director';
import { KeyboardService } from 'src/app/services/keyboard/keyboard.service';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { PlacesAutocompletePage } from 'src/app/pages/misc/places-autocomplete/places-autocomplete.page';
import { UberEventsService } from '../services/uber-events.service';
import { UserParams } from 'src/app/models/user';
import { WebflowVariant } from '../webflow-start/webflow-start.variants';

interface WebflowSignUpPageProps {
  onAlreadyHasAccount: () => void;
  addressParams: UserParams;
  webflowVariant: WebflowVariant;
}

@Component({
  selector: 'ysh-webflow-signup',
  templateUrl: './webflow-signup.page.html',
  styleUrls: ['./webflow-signup.page.scss'],
})
export class WebflowSignUpPage implements OnInit, FlowPage {
  @ViewChild('searchbarZipCode') searchbarZipCode: IonSearchbar;
  @ViewChild('inputFirstName') inputFirstName: IonInput;
  @ViewChild('inputLastName') inputLastName: IonInput;
  @ViewChild('inputPhone') inputPhone: IonInput;
  @ViewChild('inputEmail') inputEmail: IonInput;
  @ViewChild('inputInviteCode') inputInviteCode: IonInput;
  @ViewChild('searchbar') searchbar: IonSearchbar;

  @Input() onDismiss: () => void;
  @Input() onComplete: () => void;

  @Input() props: WebflowSignUpPageProps;

  googleAutocomplete: google.maps.places.Autocomplete;
  formData: UserParams = {};
  zipCode: string;
  phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(
    public platform: Platform,
    private userService: UserService,
    private loadingAlertCrtl: LoadingAlertService,
    private analytics: AnalyticsService,
    private keyboardService: KeyboardService,
    private navigation: NavigationService,
    private modalService: ModalService,
    private uberService: UberEventsService,
    public backButtonService: BackButtonService
  ) {}

  // life cycle hooks
  ngOnInit() {
    this.analytics.trackView('WebflowSignUpPage');
    this.formData.inviteCode = this.navigation.initParams.get(QueryParamKey.InviteCode);
  }

  ionViewWillEnter() {
    this.keyboardService.disableScroll(true);
    setTimeout(() => {
      this.inputFirstName.setFocus();
    }, 600);
  }

  ionViewWillLeave() {
    this.keyboardService.close();
    this.keyboardService.disableScroll(false);
  }

  // Data

  geocodeZipCode(zipCode: string): Observable<AddressParams> {
    const geocoder = new google.maps.Geocoder();
    const geocodeAsObservable = bindCallback(geocoder.geocode);
    return geocodeAsObservable({ address: String(zipCode) }).pipe(
      map(([results, status]) => {
        if (status !== google.maps.GeocoderStatus.OK) {
          throw new Error('Invalid Zip: ' + status);
        }
        if (!status[0]) {
          throw new Error('Invalid Zip: Not found');
        }
        const params = addressParamsForGooglePlaceData(results[0] as any);
        return params;
      })
    );
  }

  async signupUser() {
    this.loadingAlertCrtl.showLoaderWithText('Logging In');
    const getLocation = this.props.webflowVariant.collectZipAtSignup
      ? this.geocodeZipCode(this.zipCode)
      : of(this.props.addressParams as AddressParams);
    getLocation
      .pipe(
        flatMap((locationParams) => {
          const signupSource = this.uberService.appIsEmbeddedInUber
            ? SignupSource.MobileWebflowUber
            : SignupSource.MobileWebflow;
          return this.userService.signup({ signupSource, ...this.formData, ...locationParams });
        }),
        finalize(() => {
          this.loadingAlertCrtl.dismissLoader();
        })
      )
      .subscribe(
        (data) => {
          this.onComplete?.();
        },
        (error) => {
          if (typeof error === 'string' && error.includes('Phone has already been taken')) {
            this.props.onAlreadyHasAccount();
          } else {
            this.loadingAlertCrtl.presentAlert('Error', error);
          }
        }
      );
  }

  formValidates() {
    return (
      this.nameValidates() &&
      this.phoneValidates() &&
      this.emailValidates() &&
      this.zipCodeValidates() &&
      this.addressValidates()
    );
  }

  zipCodeValidates() {
    return !this.props.webflowVariant.collectZipAtSignup || String(this.zipCode)?.length === 5;
  }

  addressValidates() {
    return (
      !this.props.webflowVariant.collectAddressAtSignup ||
      (this.formData?.locationName &&
        this.formData?.lat &&
        this.formData?.lng &&
        this.formData?.zipCode)
    );
  }

  nameValidates() {
    return this.formData.firstName && this.formData.lastName;
  }

  phoneValidates() {
    return this.formData.phone && this.formData.phone.length === 14;
  }

  emailValidates() {
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}/;
    return this.formData.email && this.formData.email.match(emailRegex);
  }

  // Actions

  didTapEnter() {
    this.inputLastName.setFocus();
  }

  didTapDone() {
    this.signupUser();
  }

  didTapBack() {
    this.onDismiss?.();
  }

  async didTapNewAddress() {
    const modal = await this.modalService.openPage({ component: PlacesAutocompletePage });
    this.keyboardService.close();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.createDataForGooglePlace(data);
    }
    setTimeout(() => {
      this.keyboardService.close();
    }, 600);
  }

  async didTapSearch() {
    this.googleAutocomplete = new google.maps.places.Autocomplete(
      await this.searchbar.getInputElement()
    );
    this.googleAutocomplete.addListener('place_changed', () => {
      const data = this.googleAutocomplete.getPlace();
      this.createDataForGooglePlace(data);
    });
    google.maps.event.clearInstanceListeners(this.searchbar.getInputElement());
    document.querySelector('.pac-container')?.remove();
  }

  createDataForGooglePlace(data) {
    const addressParams = addressParamsForGooglePlaceData(data);
    if (addressParams.zipCode) {
      this.props.addressParams = addressParams;
      this.formData = { ...this.formData, ...addressParams };
    } else {
      this.loadingAlertCrtl.showToastAlert('Please pick a more specific address.');
    }
  }
}
