import * as Sentry from '@sentry/angular';

import { environment as ENV } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { User } from 'src/app/models/user';
import { UserService } from '../api/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorReportingService {
  static readonly SENTRY_DSN = ENV.SENTRY_DSN;
  static readonly SENTRY_RELEASE = ENV.SENTRY_RELEASE;
  ignoreErrorUrls: RegExp[] = [/https:\/\/maps\.googleapis\.com/];
  ignoreErrorMessages: string[] = ['Error Code: 401'];

  constructor(private userService: UserService) {}

  init() {
    if (!ErrorReportingService.SENTRY_DSN) {
      console.error('Cordova or SENTRY_DSN not available');
      return;
    }
    Sentry.init({
      dsn: ErrorReportingService.SENTRY_DSN,
      denyUrls: this.ignoreErrorUrls,
      ignoreErrors: this.ignoreErrorMessages,
      environment: ENV.name,
    });

    this.userService.currentUser$.subscribe((user) => {
      this.setUserContext(user || undefined);
    });
  }

  capture(error) {
    Sentry.captureException(error);
  }

  private setUserContext(user?: User) {
    if (user) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: user.uid,
          email: user.email,
        });
      });
    } else {
      Sentry.configureScope((scope) => scope.clear());
    }
  }
}
