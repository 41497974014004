import { Alert, AlertBulkUpdateParams } from 'src/app/models/alert';
import { ApiService, AuthData, EmptySubscriber } from '../api.service';
import { AppState, getAll, getCollection } from 'src/state/state';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { CreditCard, CreditCardParams } from '../../../models/credit-card';
import { NavigationService, QueryParamKey } from 'src/app/navigation.service';
import { User, UserParams, UserUpdateParams } from 'src/app/models/user';
import { finalize, flatMap, map, tap } from 'rxjs/operators';

import { Badge } from '@ionic-native/badge/ngx';
import { Benefit } from 'src/app/models/benefit';
import { CollectionActions } from 'src/state/actions';
import { EventsService } from '../../events/events.service';
import { Injectable } from '@angular/core';
import { MemberSubscription } from 'src/app/models/member-subscription';
import { Oem } from '../../oem/oem.service';
import { Platform } from '@ionic/angular';
import { Receipt } from 'src/app/models/receipt';
import { SessionService } from '../../session/session.service';
import { Store } from '@ngrx/store';

export enum SignupSource {
  Mobile = 'mobile',
  MobileWebflow = 'mobile_webflow',
  MobileWebflowUber = 'mobile_webflow_uber',
  MobileWebflowUberWithData = 'mobile_webflow_uber_with_data',
  MobileWebflowUberWithDataNoVehicle = 'mobile_webflow_uber_with_data_no_vehicle',
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  static readonly USER_DATA_KEY = 'current_user';
  static readonly AUTH_DATA_KEY = 'auth';

  readonly currentUser$ = new BehaviorSubject<Nullable<User>>(null);
  readonly isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  alerts$: Observable<Alert[]>;
  benefits$: Observable<Benefit[]>;
  pendingBenefits$: Observable<Benefit[]>;
  creditCards$: Observable<CreditCard[]>;

  alertsUnreadCount$ = new BehaviorSubject(0);

  alertsLoaded$: Observable<boolean>;
  creditCardsLoading$: Observable<boolean>;
  benefitsLoaded$: Observable<boolean>;

  newUser = false;

  private loading: Promise<void>;

  constructor(
    private api: ApiService,
    private session: SessionService,
    private store: Store<AppState>,
    private collectionActions: CollectionActions,
    private badge: Badge,
    private events: EventsService,
    private platform: Platform,
    private navigation: NavigationService
  ) {
    this.loading = this.load();
    this.initStreams();
  }

  private initStreams() {
    this.creditCards$ = this.store.select((state: AppState) => getAll(state, CreditCard));
    this.benefits$ = this.store
      .select((state) => getAll(state, Benefit))
      .pipe(
        map((benefits) => {
          return benefits.filter((benefit) => benefit.active);
        })
      );
    this.pendingBenefits$ = this.store
      .select((state) => getAll(state, Benefit))
      .pipe(
        map((benefits) => {
          return benefits.filter((benefit) => !benefit.active);
        })
      );
    this.alerts$ = this.store.select((state) => getAll(state, Alert));
    this.alerts$.subscribe((alerts) => {
      this.updateBadgeForAlerts(alerts);
    });
    this.alertsLoaded$ = this.store.select((state) => getCollection(state, Alert).loaded);
    this.creditCardsLoading$ = this.store.select(
      (state) => getCollection(state, CreditCard).loading
    );
    this.benefitsLoaded$ = this.store.select((state) => getCollection(state, Benefit).loaded);
  }

  private async load() {
    const userData = await this.session.get(UserService.USER_DATA_KEY);
    const authData = await this.session.get(UserService.AUTH_DATA_KEY);
    const user = userData && new User(authData);
    if (user) {
      this.currentUser$.next(user);
    }
    if (authData) {
      this.loginWithAuthData(authData);
    }
  }

  signup(data: UserParams): Observable<Nullable<User>> {
    data.signupRef =
      this.navigation.initParams.get(QueryParamKey.SignupRef) || this.navigation.currentPath();
    data.signupSource = data.signupSource || SignupSource.Mobile;
    data.skipMembership = this.navigation.inWebFlow();
    return this.api
      .call({
        url: '/users',
        method: 'POST',
        body: data,
      })
      .pipe(
        flatMap((data) => {
          data.user_uid = data.uid;
          data.user_id = data.id;
          return from(this.loginWithAuthData(data, true));
        })
      );
  }

  login(phone: string, pin: string): Observable<Nullable<User>> {
    return this.api
      .call({
        url: '/sessions',
        method: 'POST',
        params: { phone, pin },
      })
      .pipe(flatMap((data) => from(this.loginWithAuthData(data))));
  }

  async loginWithAuthData(data: AuthData, newUser = false) {
    await this.setAuthData(data);
    this.newUser = newUser;
    this.isLoggedIn$.next(true);
    this.events.publish(UserEvent.UserDidLogin);
    return this.api.authData ? this.getUser().toPromise() : Promise.resolve(null);
  }

  async logout() {
    await this.loading;
    await this.session.clear();
    this.store.dispatch(this.collectionActions.clearAll());
    this.currentUser$.next(null);
    this.isLoggedIn$.next(false);
    this.api.authData = null;
    this.events.publish(UserEvent.UserDidLogout);
    this.deleteSession();
  }

  private deleteSession() {
    this.api.call({
      url: '/sessions/current',
      method: 'DELETE',
    });
  }

  sendPin(phone: string) {
    return this.api.call({
      url: '/pin_send',
      method: 'POST',
      body: { phone },
    });
  }

  getUser() {
    const response = this.api
      .call({
        url: `/users/${ApiService.USER_UID}`,
        method: 'GET',
      })
      .pipe(
        map((result) => new User(result)),
        tap(
          (user) => {
            if (!this.isLoggedIn$.value) {
              return;
            }
            this.currentUser$.next(user);
            this.setMembershipData(user);
            this.session.set(UserService.USER_DATA_KEY, user.json);
          },
          (error) => {
            if (error.status === 401) {
              this.logout();
              return null;
            }
          }
        )
      );
    response.subscribe(new EmptySubscriber());
    return response;
  }

  private setMembershipData(user: User) {
    const memberships = user.memberSubscriptions.filter((membership) => membership.active);
    this.store.dispatch(this.collectionActions.set(MemberSubscription, memberships));
  }

  updateUser(params: UserUpdateParams) {
    const response = this.api
      .call({
        url: `/users/${ApiService.USER_UID}`,
        method: 'PATCH',
        body: params,
      })
      .pipe(
        map((result) => new User(result)),
        tap((data) => {
          this.currentUser$.next(data);
          this.session.set(UserService.USER_DATA_KEY, data.json);
        })
      );
    response.subscribe(new EmptySubscriber());
    return response;
  }

  refreshGMToken(token: string) {
    const request = this.api.call({
      url: `/users/${ApiService.USER_UID}/gm_refresh_token`,
      method: 'POST',
      body: { auth_code: token },
    });
    request.subscribe(new EmptySubscriber());
    return request;
  }

  refreshToyotaToken(token: string, make: Oem) {
    const request = this.api.call({
      url: `/users/${ApiService.USER_UID}/toyota_refresh_token`,
      method: 'POST',
      body: { auth_code: token, make },
    });
    request.subscribe(new EmptySubscriber());
    return request;
  }

  sendPromoCode(code: string) {
    return this.api
      .call({
        url: `/users/${ApiService.USER_UID}/use_invite`,
        method: 'POST',
        body: { invite_code: code },
      })
      .pipe(tap(() => this.getAppliedBenefits()));
  }

  async getAuthData() {
    const authData = await this.session.get(UserService.AUTH_DATA_KEY);
    return authData;
  }

  // Company Id
  createAffiliation(company_uid: string, external_id: string) {
    return this.api.call({
      url: `/companies/${company_uid}/affiliations`,
      method: 'POST',
      body: { external_id },
    });
  }

  // Device

  registerDevice(push_id: string, platform: string) {
    console.log('try to register device', push_id, platform);
    let response = this.api.call({
      url: `/users/${ApiService.USER_UID}/devices`,
      method: 'POST',
      body: { push_id, type: platform },
    });
    response.subscribe(
      (data) => {
        console.log('device save succeess', data);
      },
      (error) => {
        console.log('device save error', error);
      }
    );
    return response;
  }

  // Credit Card

  getCreditCards(): Observable<[CreditCard]> {
    this.store.dispatch(this.collectionActions.setLoading(CreditCard, true));
    const request = this.api
      .call({
        method: 'GET',
        url: `/users/${ApiService.USER_UID}/credit_cards`,
      })
      .pipe(
        map((result) => result.map((item) => new CreditCard(item))),
        finalize(() => {
          this.store.dispatch(this.collectionActions.setLoading(CreditCard, false));
        }),
        tap((cards) => {
          this.store.dispatch(this.collectionActions.set(CreditCard, cards));
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  createCreditCard(params: CreditCardParams) {
    const request = this.api
      .call({
        method: 'POST',
        url: `/users/${ApiService.USER_UID}/credit_cards`,
        body: params,
      })
      .pipe(
        map((item) => new CreditCard(item)),
        tap((card) => {
          this.store.dispatch(this.collectionActions.add(CreditCard, card));
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  deleteCreditCard(card: CreditCard) {
    const request = this.api.call({
      method: 'DELETE',
      url: '/credit_cards/' + card.uid,
    });
    request
      .pipe(
        tap((data) => {
          this.store.dispatch(this.collectionActions.delete(CreditCard, card));
        })
      )
      .subscribe(new EmptySubscriber());
    return request;
  }

  // Alerts

  getAlerts(page: number = 1) {
    this.store.dispatch(this.collectionActions.setLoading(Alert, true));
    const request = this.api
      .call({
        method: 'GET',
        url: `/users/${ApiService.USER_UID}/alerts`,
        includePageInfo: true,
        params: { page },
      })
      .pipe(
        map((result) => {
          return {
            items: result.response.map((item) => new Alert(item)),
            pageInfo: result.pageInfo,
          };
        }),
        finalize(() => this.store.dispatch(this.collectionActions.setLoading(Alert, false))),
        tap((data) => {
          if (page > 1) {
            this.store.dispatch(this.collectionActions.addMany(Alert, data.items));
          } else {
            this.store.dispatch(this.collectionActions.set(Alert, data.items));
          }
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  updateAlerts(params: AlertBulkUpdateParams) {
    let request = this.api
      .call({
        method: 'PATCH',
        url: `/users/${ApiService.USER_UID}/alerts`,
        body: params,
      })
      .pipe(
        map((items) => {
          return items.map((item) => new Alert(item));
        }),
        tap((alerts: Alert[]) => {
          this.store.dispatch(this.collectionActions.updateMany(Alert, alerts));
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  // Applied Benefits

  getAppliedBenefits() {
    const request = this.api
      .call({
        url: `/users/${ApiService.USER_UID}/applied_benefits`,
        method: 'GET',
      })
      .pipe(
        map((result) => result.map((item) => new Benefit(item))),
        tap((benefits) => {
          this.store.dispatch(this.collectionActions.set(Benefit, benefits));
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  redeemBenefit(benefit: Benefit) {
    const request = this.api
      .call({
        method: 'POST',
        url: `/applied_benefits/${benefit.uid}/redeem`,
      })
      .pipe(
        map((result) => new Benefit(result)),
        tap((benefit) => {
          this.store.dispatch(this.collectionActions.update(Benefit, benefit));
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  private updateBadgeForAlerts(alerts: Alert[]) {
    let count = 0;
    for (let alert of alerts) {
      if (!alert.readAt) count++;
    }
    if (this.platform.is('cordova')) {
      this.didAllowPushNotifications().then((didAllow) => {
        if (didAllow) {
          this.badge.set(count);
        }
      });
    }
    this.alertsUnreadCount$.next(count);
  }

  getUserReceipts(page = 1) {
    const request = this.api
      .call({
        url: `/users/${ApiService.USER_UID}/invoices`,
        includePageInfo: true,
        params: { page },
      })
      .pipe(
        map((res) => {
          const response = res.response || [];
          return response.map((receipt) => new Receipt(receipt));
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  // Private Methods

  private async setAuthData(data: AuthData) {
    await this.session.clear();
    this.api.authData = data;
    return this.session.set(UserService.AUTH_DATA_KEY, data);
  }

  private didAllowPushNotifications(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.session.get('has_been_prompted_for_push').then((prompted) => {
        this.session.get('did_decline_push').then((declined) => {
          if (prompted && !declined) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    });
  }
}

export enum UserEvent {
  UserDidLogout = 'userDidLogout',
  UserDidLogin = 'userDidLogin',
}
